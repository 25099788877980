
import React from "react"

import WebappsConversationpageComponent from "../../components/webapps/components/conversationpage"

const SMSFullPage = ({location}) => {
	return <WebappsConversationpageComponent
			allowadd={true}
			location={location}
		/>

}


export default SMSFullPage;
